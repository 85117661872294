import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import FollowerCard from '../Cards/FollowerCard';
import SuggestedUserCard, {
  SuggestedUserProfile,
} from '../Cards/SugestedUserCard';

import SkeletonLoader from './SkeletonLoader';

interface ICreatorsListProps<T> {
  currentUsername?: string;
  supportDiffs?: boolean;
  higlightTopItem?: boolean;
  isFollowerCard?: boolean;
  useQueryHook: (
    skip: number,
    num: number,
    user_id?: string,
    staked?: boolean,
  ) => {
    data: T[] | undefined;
    isLoading: boolean;
    isError: boolean;
    refetch: () => Promise<{ data: T[] | undefined }>;
  };

  userId?: string;
  filterStakedOnly?: boolean;
}

export default function CreatorsList<T>({
  currentUsername,
  supportDiffs,
  higlightTopItem,
  isFollowerCard,
  useQueryHook,
  userId,
  filterStakedOnly = false,
}: ICreatorsListProps<T>) {
  const [page, setPage] = useState(1);
  const [creators, setCreators] = useState<T[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 30;
  const skip = (page - 1) * itemsPerPage;
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const {
    data: list = [],
    isLoading,
    isError,
    refetch,
  } = useQueryHook(skip, itemsPerPage, userId, filterStakedOnly);

  // Effect to handle data fetching when the page changes
  useEffect(() => {
    if (!hasMore) return; // If no more data is available, do nothing

    refetch().then((event) => {
      const fetchedData = event?.data ?? [];

      // Update the hasMore state based on the number of fetched items
      if (fetchedData.length < itemsPerPage) {
        setHasMore(false);
      }

      setCreators((prev) => {
        const newCreators = [...prev, ...fetchedData];
        const uniqueCreators = Array.from(
          new Set(newCreators.map((c: any) => c.username)),
        ).map(
          (username) =>
            newCreators.find((c: any) => c.username === username) as T,
        );
        return uniqueCreators;
      });
      setIsFetching(false);
    });
  }, [page, refetch, hasMore]);

  useEffect(() => {
    refetch().then((event) => {
      const fetchedData = event?.data ?? [];

      setHasMore(fetchedData.length >= itemsPerPage);
      setCreators(fetchedData);
      setIsFetching(false);
    });
  }, [filterStakedOnly]);

  // Callback function to handle scroll events and trigger pagination
  const handleScroll = useCallback(() => {
    if (isFetching || isLoading || !hasMore) return;

    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollContainer;

    // Load more data if the user scrolls near the bottom of the container
    if (scrollTop + clientHeight >= scrollHeight - 500) {
      setIsFetching(true);
      setPage((prevPage) => prevPage + 1);
    }
  }, [isFetching, isLoading, hasMore]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  if (isLoading && creators.length === 0) {
    return <SkeletonLoader />;
  }

  if (isError) {
    return (
      <Typography sx={{ p: 6, color: 'red' }}>Error loading data</Typography>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '80vh',
        overflow: 'auto',
        pr: 0,
      }}
      ref={scrollContainerRef}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 4,
          border: '1px solid #262829',
        }}
      >
        {creators.map((creator: T, index: number) =>
          isFollowerCard ? (
            <FollowerCard
              key={index}
              profile={creator as SuggestedUserProfile}
              hasBorderRadiusTop={index === 0}
            />
          ) : (
            <SuggestedUserCard
              key={(creator as any).username}
              profile={creator as any}
              subscribers={(creator as any).subs}
              hasBorderRadiusTop={index === 0}
              positionNumber={index + 1}
              isMe={
                !!currentUsername &&
                (creator as any).username === currentUsername
              }
              supportDiffs={supportDiffs}
              higlightTopItem={higlightTopItem}
              sharedRevenueRoc={(creator as any).shared_revenue_roc}
              tvlRoc={(creator as any).tvl_roc}
            />
          ),
        )}
      </Box>
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 6 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
