import {
  Avatar,
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { truncAddress } from '../../../lib/truncHash';
import { ICreator } from '../../../types/ICreator';
import { StakedStatusIcon } from '../../icons';

export type SuggestedUserProfile = Pick<
  ICreator,
  | 'username'
  | 'avatar'
  | 'tvl'
  | 'shared_revenue'
  | 'wallet_address'
  | 'subscription_type'
> & {
  is_staked: boolean;
};

export default function FollowerCard({
  profile,
  hasBorderRadiusTop = false,
  hasBorderBottom = true,
}: {
  profile: SuggestedUserProfile;
  hasBorderRadiusTop?: boolean;
  hasBorderBottom?: boolean;
}) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const membershipType =
    profile.subscription_type === 'monthly' ? 'Monthly' : 'ALPHA';
  const membershipTypeText = membershipType;

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        color: '#F4F4F5',
        fontWeight: 400,
        alignItems: 'center',
        position: 'relative',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          bgcolor: '#111314',
        },
        borderTopRightRadius: hasBorderRadiusTop ? '16px' : 0,
        borderTopLeftRadius: hasBorderRadiusTop ? '16px' : 0,
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          py: 5,
          pl: 5,
          alignItems: 'center',
        }}
      >
        <Avatar src={profile.avatar} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Typography fontSize="16px">{profile.username}</Typography>

              {profile.is_staked && <StakedStatusIcon />}
            </Box>

            <Typography fontSize="14px" color="#9B9FA3">
              {isMobile
                ? truncAddress(profile.wallet_address)
                : profile.wallet_address}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', pr: 5 }}>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 300,
            color: '#9B9FA3',
          }}
        >
          {membershipTypeText}
        </Typography>
      </Grid>

      {hasBorderBottom && <Divider orientation="horizontal" />}
    </Grid>
  );
}
