import { Box, Grid, Skeleton } from '@mui/material';

export default function SkeletonLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 6,
        height: '80vh',
        overflow: 'auto',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {[...Array(5)].map((_, index) => (
          <Grid
            container
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 5,
              borderBottom: index < 5 ? '1px solid #222426' : 'none',
            }}
          >
            <Grid item xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <Skeleton variant="circular" width={40} height={40} />
                <Box>
                  <Skeleton width={90} height={15} />
                  <Skeleton
                    width={52}
                    height={15}
                    sx={{ backgroundColor: '#111314' }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
}
