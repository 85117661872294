import { useEffect, useState } from 'react';

import { Box, Drawer, IconButton, Typography } from '@mui/material';

import { CloseIcon } from '../../icons';
import { StakedOnlySwitcher } from '../Switches/StakedOnlySwitcher';

import CreatorsList from './CreatorsList';

interface IAllCreatorsDrawer<T> {
  toggleDrawer: (open: boolean) => void;
  open: boolean;
  title: string;
  currentUsername?: string;
  supportDiffs?: boolean;
  higlightTopItem?: boolean;
  isFollowerCard?: boolean;
  useQueryHook: (
    skip: number,
    num: number,
    userId?: string,
    staked?: boolean,
  ) => any;
  userId?: string;
  showStakedOnlyFilter?: boolean;
  stakedOnlyInitial?: boolean;
}

export default function AllCreatorsDrawer<T>({
  toggleDrawer,
  open,
  title,
  currentUsername,
  supportDiffs,
  higlightTopItem,
  isFollowerCard,
  useQueryHook,
  userId,
  showStakedOnlyFilter = false,
  stakedOnlyInitial = false,
}: IAllCreatorsDrawer<T>) {
  const [stakedOnly, setStakedOnly] = useState<boolean>(stakedOnlyInitial);

  // Need to reset stakedOnly flag to initial value when reopen drawer
  useEffect(() => {
    if (!open) return;

    setStakedOnly(stakedOnlyInitial);
  }, [stakedOnlyInitial, open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => toggleDrawer(false)}
      PaperProps={{ sx: { width: { xs: '100%', md: '40%' } } }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 6 }}>
        <IconButton aria-label="close" onClick={() => toggleDrawer(false)}>
          <CloseIcon stroke="#828282" height={20} width={20} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pl: 6,
          pr: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 6,
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: '24px' }}>
            {title}
          </Typography>
        </Box>
        {open && (
          <Box display="flex" flexDirection="column" flexGrow={1} gap={6}>
            {showStakedOnlyFilter && (
              <StakedOnlySwitcher value={stakedOnly} setValue={setStakedOnly} />
            )}

            <CreatorsList
              currentUsername={currentUsername}
              supportDiffs={supportDiffs}
              higlightTopItem={higlightTopItem}
              isFollowerCard={isFollowerCard}
              useQueryHook={useQueryHook}
              userId={userId}
              filterStakedOnly={stakedOnly}
            />
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
