import { FormControlLabel, Switch } from '@mui/material';

export function StakedOnlySwitcher({
  value,
  setValue,
}: {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <FormControlLabel
      control={
        <Switch checked={value} onChange={() => setValue((old) => !old)} />
      }
      label="Show stakers only"
      labelPlacement="start"
      slotProps={{
        typography: { fontSize: 16, fontWeight: 400 },
      }}
      sx={{
        margin: 0,
        '& .MuiFormControlLabel-label': {
          marginRight: 2,
        },
      }}
    />
  );
}
