import { useState } from 'react';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import { CREATORS_PATH } from '../../../constants/routings';
import { usePriceInUsd } from '../../../hooks/usePriceInUsd';
import navigate from '../../../lib/navigate';
import { ICreator } from '../../../types/ICreator';
import { Position1, Position2, Position3 } from '../../icons';
import { ChevronRight } from '../../icons/chevronRight';
import { formatValue } from '../../../lib/formatWithDecimal';

export type SuggestedUserProfile = Pick<
  ICreator,
  | 'username'
  | 'avatar'
  | 'shared_revenue'
  | 'shared_revenue_roc'
  | 'total_revenue'
  | 'total_revenue_roc'
>;

export default function SuggestedUserCard({
  profile,
  subscribers,
  hasBorderRadiusTop = false,
  positionNumber,
  positionGained,
  isMe = false,
  supportDiffs,
  higlightTopItem,
  sharedRevenueRoc,
  creatorRevenueRoc,
  hasBorderBottom = true,
}: {
  profile: SuggestedUserProfile;
  subscribers: number;
  hasBorderRadiusTop?: boolean;
  positionNumber?: number;
  positionGained?: number;
  isMe: boolean;
  supportDiffs?: boolean;
  higlightTopItem?: boolean;
  sharedRevenueRoc?: number;
  creatorRevenueRoc?: number;
  hasBorderBottom?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const { convertToUSD, isLoading: isTvlLoading } = usePriceInUsd();

  const renderPositionIcon = () => {
    switch (positionNumber) {
      case 1:
        return <Position1 />;
      case 2:
        return <Position2 />;
      case 3:
        return <Position3 />;
      default:
        return null;
    }
  };

  const handleClick = () => {
    navigate(`${CREATORS_PATH}/${profile.username}`);
  };

  const isCreatorRevenueRocPositive =
    creatorRevenueRoc && creatorRevenueRoc > 0;

  const shouldDisplayCreatorRevenueRoc =
    supportDiffs &&
    typeof creatorRevenueRoc === 'number' &&
    creatorRevenueRoc !== 0;

  const showSharedRevenueRoc = supportDiffs && !!sharedRevenueRoc;

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        color: '#F4F4F5',
        fontWeight: 400,
        alignItems: 'center',
        position: 'relative',
        transition: 'all 0.3s ease-in-out',
        background:
          higlightTopItem && positionNumber === 1
            ? 'linear-gradient(129.94deg, #43FA97 -407.65%, rgba(67, 250, 151, 0) 158.17%)'
            : isMe
            ? '#222426'
            : '',
        '&:hover': {
          cursor: 'pointer',
          bgcolor: '#111314',
        },
        borderTopRightRadius: hasBorderRadiusTop ? '16px' : 0,
        borderTopLeftRadius: hasBorderRadiusTop ? '16px' : 0,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <Grid
        item
        xs={8}
        md={6}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          py: 5,
          pl: 5,
          alignItems: 'center',
        }}
      >
        {positionNumber && (
          <>
            {renderPositionIcon() || (
              <Typography
                sx={{
                  fontSize: '16px',
                  display: 'flex',
                  width: '32px',
                  justifyContent: 'center',
                }}
              >
                <span style={{ fontSize: '10px', alignSelf: 'start' }}>#</span>
                {positionNumber}
              </Typography>
            )}
          </>
        )}
        <Avatar
          src={profile.avatar}
          sx={{
            width: { xs: '36px', md: '40px' },
            height: { xs: '36px', md: '40px' },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box display="flex">
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
              {profile.username}
            </Typography>

            {isMe && (
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: 1,
                  color: '#38FF93',
                  border: '1px solid #38FF93',
                  borderRadius: '12px',
                  padding: '4px 8px',
                  ml: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                ME
              </Typography>
            )}
          </Box>

          <Typography
            sx={{
              display: 'flex',
              gap: { xs: 1, md: 2 },
              fontSize: { xs: '12px', md: '14px' },
            }}
          >
            {subscribers}
            <span style={{ fontWeight: 300, color: '#9B9FA3' }}>Members</span>
          </Typography>
        </Box>
      </Grid>

      {positionGained ? (
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            py: 5,
            pr: 5,
            width: '120px',
            alignItems: 'flex-end',
            transition: 'transform 0.3s ease-in-out',
            transform: isHovered ? 'translateX(-30px)' : 'translateX(0)',
          }}
        >
          <Typography sx={{ fontSize: '16px' }}>+ {positionGained}</Typography>
          <Typography sx={{ fontWeight: 300, color: '#9B9FA3' }}>
            Position gained
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={4}
            md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              py: 5,
              pr: 5,
              alignItems: 'flex-end',
              transition: 'transform 0.3s ease-in-out',
              transform: isHovered ? 'translateX(-30px)' : 'translateX(0)',
            }}
          >
            <Box display="flex" gap={2}>
              {shouldDisplayCreatorRevenueRoc && (
                <Typography
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    color: isCreatorRevenueRocPositive ? '#38FF93' : '#FF1919',
                  }}
                >
                  {isCreatorRevenueRocPositive ? '↑' : '↓'}{' '}
                  {formatValue(creatorRevenueRoc, 0)}%
                </Typography>
              )}

              <Typography sx={{ fontSize: { xs: '14px', md: '16px' } }}>
                ${convertToUSD(profile.total_revenue)}
              </Typography>
            </Box>

            <Typography
              sx={{
                fontWeight: 300,
                color: '#9B9FA3',
                fontSize: { xs: '12px', md: '14px' },
              }}
            >
              Creator Revenue
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexDirection: 'column',
              gap: 1,
              py: 5,
              pr: 5,
              width: '120px',
              alignItems: 'flex-end',
              transition: 'transform 0.3s ease-in-out',
              transform: isHovered ? 'translateX(-30px)' : 'translateX(0)',
            }}
          >
            <Box display="flex" gap={2}>
              {showSharedRevenueRoc && (
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  ↑ {formatValue(sharedRevenueRoc, 0)}%
                </Typography>
              )}

              <Typography sx={{ fontSize: '16px' }}>
                ${convertToUSD(profile.shared_revenue)}
              </Typography>
            </Box>

            <Typography
              sx={{
                fontWeight: 300,
                color: '#9B9FA3',
                fontSize: { xs: '12px', md: '14px' },
              }}
            >
              Shared Revenue
            </Typography>
          </Grid>
        </>
      )}
      <Box
        sx={{
          position: 'absolute',
          right: '20px',
          top: '50%',
          transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
          opacity: isHovered ? 1 : 0,
          transform: isHovered
            ? 'translateY(-50%)'
            : 'translateY(-50%) translateX(10px)',
        }}
      >
        <ChevronRight size={16} />
      </Box>
      {hasBorderBottom && <Divider orientation="horizontal" />}
    </Grid>
  );
}
